import React from "react"
import cx from "classnames"
import * as styles from "../../styles.module.css"

interface DescriptionProps {
  children: string
}

const Description = ({ children }: DescriptionProps) => {
  const descriptionClasses = cx(
    "text-white font-barlow sm:text-sm lg:text-base lineHeight-text font-light mb-6",
    styles.descriptionAndSubtitle
  )

  return (
    <>
      <p
        dangerouslySetInnerHTML={{ __html: children }}
        className={descriptionClasses}
      />
    </>
  )
}

export default Description
