import cx from "classnames"
import React from "react"

import _map from "lodash/map"
import _size from "lodash/size"
import Badges from "../Badges"
import Button from "../Button"
import Description from "./components/Description"
import Media from "./components/Media"
import SubTitle from "./components/SubTitle"
import Title from "./components/Title"

export interface Props {
  rowReverse?: boolean
  linearGradient?: boolean
  backgroundColor1: string
  backgroundColor2: string
  title: string
  subtitle: string
  description: string
  badge: [
    {
      icon?: string
      text: string
    }
  ]
  media: {
    video_url?: string
    image?: string
    image_description?: string
  }
  button: [
    {
      children: string
      type?: "submit" | "reset" | "button"
      preset:
      | "solidCyan"
      | "outlineCyan"
      | "solidBlackPearl"
      | "outlineBlackPearl"
      onClick: () => void
    }
  ]
}

const Hero = ({
  linearGradient,
  rowReverse,
  backgroundColor1,
  backgroundColor2,
  badge,
  title,
  subtitle,
  description,
  button,
  media,
}: Props): JSX.Element => {
  let sectionClasses = cx("lg:flex items-center justify-center")

  let style = {
    background: backgroundColor1,
  }
  if (linearGradient && backgroundColor2) {
    style.background = `linear-gradient(to bottom,  ${backgroundColor1} 0%,${backgroundColor2} 100%)`
  }

  const gridClasses = cx("m-auto main-container")
  const heroClasses = cx("py-8 md:flex", {
    "md:flex md:flex-row-reverse": rowReverse,
  })
  const buttonClasses = cx("md:flex")
  const contentClasses = cx("flex flex-col md:w-5/12 md:justify-center", {
    "md:mr-4": !rowReverse,
    "md:ml-4": rowReverse,
  })
  const audioVisualClasses = cx("mt-6 md:mt-0 md:w-7/12 self-center", {
    "md:mr-3": rowReverse,
    "md:ml-3": !rowReverse,
  })

  return (
    <>
      <section className={sectionClasses} style={style}>
        <div className={gridClasses}>
          <div className={heroClasses}>
            <div className={contentClasses}>
              <div className="flex flex-row">
                {_map(badge, (item, index) => (
                  <div key={index}>
                    <Badges text={item.text} index={index} icon={item.icon} />
                  </div>
                ))}
              </div>
              <Title>{title}</Title>
              {subtitle && <SubTitle>{subtitle}</SubTitle>}
              {description && <Description>{description}</Description>}
              <div className={buttonClasses}>
                {_map(button, (item, index) => {
                  return index !== _size(button) - 1 ? (
                    <div className="sm:mb-6 md:mb-0 md:mr-5" key={index}>
                      <Button
                        preset={item.preset}
                        onClick={item.onClick}
                        type={item.type}
                      >
                        {item.children}
                      </Button>
                    </div>
                  ) : (
                    <div key={index}>
                      <Button
                        preset={item.preset}
                        onClick={item.onClick}
                        type={item.type}
                      >
                        {item.children}
                      </Button>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={audioVisualClasses}>
              <Media {...media} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
