export const IMAGES = [
  {
    label: "imgDigitalSolitions",
    content: require("./background-block.png"),
  },
  { label: "imgTechnologies", content: require("./technologies.png") },
  { label: "qaHeroQuality", content: require("./qaHeroQuality.png") },
  { label: "qaHeroSecurity", content: require("./qaHeroSecurity.png") },
  {
    label: "designHeroBackground",
    content: require("./design-hero-background.png"),
  },
  {
    label: "designCarouselPage",
    content: require("./design-carousel-page.png"),
  },

  // carousel design-de-solucoes
  { label: "carouselAutoscar", content: require("./carousel-autoscar.png") },
  {
    label: "carouselBiogenetics",
    content: require("./carousel-biogenetics.png"),
  },
  { label: "carouselAvecFolie", content: require("./carousel-avecFolie.png") },
  {
    label: "carouselSuaVistoria",
    content: require("./design-carousel-page.png"),
  },

  {
    label: "eneAcademyHeroDevelop",
    content: require("./eneAcademyHeroDevelop.png"),
  },
  {
    label: "eneAcademyHeroProfile",
    content: require("./eneAcademyHeroProfile.png"),
  },
  { label: "eneAcademyTimeline", content: require("./eneAcademyTimeline.png") },
  {
    label: "eneAcademyBackgroundForm",
    content: require("./eneAcademyBackgroundForm.png"),
  },
  { label: "eneAcademyIconContentPlay", content: require("./play.svg") },
  {
    label: "eneAcademyIconContentSaveTheDate",
    content: require("./saveTheDate.svg"),
  },
  {
    label: "eneAcademyIconContentComputer",
    content: require("./computer.svg"),
  },
  {
    label: "eneAcademyIconContentContract",
    content: require("./contract.svg"),
  },
  {
    label: "BgTabletBlog",
    content: require("./bg-tablet-blog.png"),
  }
]
