import React from "react"
import cx from "classnames"
import styles from "../../styles.module.css"
interface SubtitleProps {
  children: string
}

const SubTitle = ({ children }: SubtitleProps) => {
  const subtitleClasses = cx(
    "text-white font-bold font-barlow sm:text-sm lg:text-base lineHeight-text"
  )

  return (
    <>
      <p
        dangerouslySetInnerHTML={{ __html: children }}
        className={subtitleClasses}
      />
    </>
  )
}

export default SubTitle
