import React from "react"
import cx from "classnames"
import * as styles from "../../styles.module.css"

interface TitleProps {
  children: string
}

const Title = ({ children }: TitleProps) => {
  const titleClasses = cx(
    "text-white font-bold font-barlow text-2.375xl lg:text-5.5xl m-0 sm:my-4 lg:my-6",
    styles.title
  )

  return (
    <>
      <h1
        dangerouslySetInnerHTML={{ __html: children }}
        className={titleClasses}
      />
    </>
  )
}

export default Title
