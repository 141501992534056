import React from "react"
import cx from "classnames"
import * as styles from "./styles.module.css"

interface ButtonProps {
  children: string
  type?: "submit" | "reset" | "button"
  preset:
    | "solidCyan"
    | "outlineCyan"
    | "solidBlackPearl"
    | "outlineBlackPearl"
    | "underlineCyan"
  onClick?: () => void
}

const Button = ({
  children,
  type = "button",
  preset,
  onClick,
}: ButtonProps) => {
  const buttonPreset = Button.PRESETS[preset] || Button.PRESETS.solidCyan
  const buttonClass = cx(
    "flex items-center justify-center",
    buttonPreset,
    styles.button
  )

  return (
    <button className={buttonClass} onClick={onClick} type={type}>
      {children}
    </button>
  )
}

Button.PRESETS = {
  solidCyan:
    "border-2 border-cyan bg-cyan hover:bg-opacity-80 text-elephant font-bold sm:text-sm lg:text-base px-8 py-3 sm:w-full md:w-auto",
  outlineCyan:
    "border-2 border-cyan text-cyan hover:text-opacity-80 px-8 py-3 font-bold sm:text-sm lg:text-base sm:w-full md:w-auto",
  solidBlackPearl:
    "border-2 border-blackPearl bg-blackPearl hover:bg-opacity-80 px-8 text-cyan font-bold sm:text-sm lg:text-base sm:w-full md:w-auto py-3",
  outlineBlackPearl:
    "border-2 border-blackPearl text-blackPearl hover:text-opacity-80 px-8 font-bold sm:text-sm py-3 lg:text-base sm:w-full md:w-auto",
  underlineCyan:
    "text-base underline font-bold text-cyan hover:text-opacity-80",
}
export default Button
